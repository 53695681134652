
export class DataAgentPro {
    tp_contacto : string;
    clte_prov: string;
    orden: string;
    nombre: string;
    cargo: string;
    departamento: string;
    direccion: string;
    codigo_postal: string;
    localidad: string;
    telefono: string;
    fax: string;
    tlf_movil: string;
    e_mail:string;
    observaciones: string;
    nif_cif: string;
    mm_sw_productor_primario: string;
}