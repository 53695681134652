import { FormGroup, ValidationErrors, ValidatorFn, AbstractControl } from "@angular/forms"

// custom validator to check that two fields match
export function ValidarIguales(controlName: string, matchingControlName: string) {
  
    return (formGroup: FormGroup) => {
       
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function ComporbarDni(controlName: string, nospain: string){
   
    return (formGroup: FormGroup) => {
        let noespa = formGroup.controls[nospain];
        let noespavalue = noespa.value;
        console.log('NO ESPA.VALUE ES',noespavalue);
       
        var numero, izq, letra;
        var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
        let control = formGroup.controls[controlName];
        if(noespavalue){
            control.setErrors(null);
            return true;
        }
        let dni = control.value;
        if(expresion_regular_dni.test(dni) === true){
            numero = dni.substr(0,dni.length-1);
            numero = numero.replace('X', 0);
            numero = numero.replace('Y', 1);
            numero = numero.replace('Z', 2);
            izq = dni.substr(dni.length-1, 1);
            numero = numero % 23;
            letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
            letra = letra.substring(numero, numero+1);
            if (letra != izq) {
                //alert('Dni erroneo, la letra del NIF no se corresponde');
                control.setErrors({mustMatch: true});
                return false;
            }else{
                //alert('Dni correcto');
                control.setErrors(null);
                return true;
            }
        }else{
            //alert('Dni erroneo, formato no válido');
            control.setErrors({mustMatch: true});
            return false;
        }
    }
}
