<!-- <div class="center">
    <h2>Solicitud de datos de apoderado para la firma de contratos a la firma:</h2>
    <h3>{{datosBD.domaing5}}</h3>
</div>
<mat-card class="m-3">
    <div class="row">
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Nombre y apellidos Apoderado</mat-label>
                <input matInput placeholder="Nombre y apellidos apoderado" [(ngModel)]="datos.Nombre_apoderado" />
              </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Dirección</mat-label>
                <input matInput placeholder="Dirección" [(ngModel)]="datos.Direccion_apoderado" />
              </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Localidad</mat-label>
                <input matInput placeholder="Localidad" [(ngModel)]="datos.Localidad_apoderado" />
              </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Código Postal</mat-label>
                <input matInput placeholder="Código Postal" [(ngModel)]="datos.Codigo_postal_apoderado" />
              </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>DNI</mat-label>
                <input matInput placeholder="DNI" [(ngModel)]="datos.DNI_apoderado" />
              </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Teléfono para recibir SMS de autenticación</mat-label>
                <input matInput placeholder="Teléfono para recibir SMS de autenticación" [(ngModel)]="datos.Telefono_SMS" />
              </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Dirección de correo para envío de contrato</mat-label>
                <input matInput placeholder="Dirección de correo para envío de contrato" [(ngModel)]="datos.e_Mail_ctos" />
              </mat-form-field>
        </div>
        <div class="com-md-12 col-xl-6">
            <button class="btn mbutton" (click)="enviar()">Enviar</button>
        </div>
    </div>
    
</mat-card> -->
<!--- Loading -->
<div *ngIf="loading" class="loading">
    <div>
        <p style="text-align:center">Esperando a Firma de Contratos AICA</p>
    </div>
    <div>
        <div class="spin"></div>
    </div>
</div>

<div *ngIf="!loading">
<div style="background-color:rgb(44, 44, 201) ;">
<div class="cabecera fondoazul">
    <mat-card style="margin-right: 30px;">
        <img src="/assets/logo dinanet.png" alt="AICA">
    </mat-card>
    <div style="width:70%">
        <h2>Solicitud de datos de apoderado para la firma de contratos</h2>
        <h3>Dominio: {{datosBD.domaing5}} </h3>
        <h3>Empresa que los solicita: {{datosBD.ape_en_representacion}}</h3>
        <h3 class="final">Proveedor para el que se solicita: {{datosBD.app_en_representacion}}</h3>
    </div>
</div>
</div>
<mat-card class="m-3 contenido">
    <form class="row" [formGroup]="form" (ngSubmit)="enviar()">
        <div class="col-md-12 col-xl-12">
        
            <mat-checkbox  formControlName="nospain" (change)="pulsaCheck($event)">Seleccione esta casilla si su nacionalidad es distinta a la española</mat-checkbox>
        
    </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Nombre y apellidos Apoderado</mat-label>
                <input name="nombre" matInput placeholder="Nombre y apellidos apoderado" formControlName="nombre"
                    required />
                <mat-hint *ngIf="form.controls['nombre'].touched && form.controls['nombre'].invalid"
                    class="alert-danger">
                    <span *ngIf="form.controls['nombre'].errors.minlength" class="error">El nombre es demasiado
                        corto</span>
                    <span *ngIf="form.controls['nombre'].errors.required" class="error"> El nombre es obligatorio</span>
                </mat-hint>
            </mat-form-field>

        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Dirección</mat-label>
                <input name="direccion" matInput placeholder="Dirección" formControlName="direccion" required />
                <mat-hint *ngIf="form.controls['direccion'].touched && form.controls['direccion'].invalid"
                    class="alert-danger">
                    <span *ngIf="form.controls['direccion'].errors.minlength" class="error">La dirección es demasiado
                        corta</span>
                    <span *ngIf="form.controls['direccion'].errors.required" class="error"> La dirección es
                        obligatoria</span>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Localidad</mat-label>
                <input name="localidad" matInput placeholder="Localidad" formControlName="localidad" required />
                <mat-hint *ngIf="form.controls['localidad'].touched && form.controls['localidad'].invalid"
                    class="alert-danger">
                    <span *ngIf="form.controls['localidad'].errors.minlength" class="error">El nombre de localidad es
                        demasiado corto</span>
                    <span *ngIf="form.controls['localidad'].errors.required" class="error"> La localidad es
                        obligatoria</span>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6" *ngIf="form.get('nospain').value == false">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Código Postal</mat-label>
                <input name="codpostal" matInput placeholder="Código Postal" formControlName="codpostal" required />
                <mat-hint *ngIf="form.controls['codpostal'].touched && form.controls['codpostal'].invalid"
                    class="alert-danger">
                    <span *ngIf="form.controls['codpostal'].errors.minlength || form.controls['codpostal'].errors.maxlength" class="error">El código postal debe contener 5 caracteres</span>
                    <span *ngIf="form.controls['codpostal'].errors.required" class="error"> El código postal es
                        obligatorio</span>

                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6" *ngIf="form.get('nospain').value == true">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Código Postal</mat-label>
                <input name="codpostalex" matInput placeholder="Código Postal" formControlName="codpostalex" (change)="actualizaCodPostal($event)" />
            </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>DNI</mat-label>
                <input name="dni" matInput placeholder="DNI" formControlName="dni" required />
                <mat-hint *ngIf="form.controls['dni'].touched && form.controls['dni'].invalid" class="alert-danger">
                    <span *ngIf="form.controls['dni'].errors.required" class="error"> El DNI es obligatorio</span>
                    <span *ngIf="form.controls['dni'].errors.mustMatch" class="error"> El formato del dni no es correcto, o la letra no esta en mayusculas</span>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Teléfono para recibir SMS de autenticación</mat-label>
                <input name="telefono" matInput placeholder="Teléfono para recibir SMS de autenticación"
                    formControlName="telefono" required />
                <mat-hint *ngIf="form.controls['telefono'].touched && form.controls['telefono'].invalid"
                    class="alert-danger">
                    <span *ngIf="form.controls['telefono'].errors.required" class="error"> El teléfono es
                        obligatorio</span>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Dirección de correo para envío de contrato</mat-label>
                <input name="email" matInput placeholder="Dirección de correo para envío de contrato"
                    formControlName="mail" required />
                <mat-hint *ngIf="form.controls['mail'].touched && form.controls['mail'].invalid" class="alert-danger">
                    <span *ngIf="form.controls['mail'].errors.email" class="error">El correo electrónico no tiene un
                        formato valido</span>
                    <span *ngIf="form.controls['mail'].errors.required" class="error"> El correo electrónico es
                        obligatorio</span>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
            <mat-form-field appearance="outline" class="myform">
                <mat-label>Confirme la dirección de correo para envío de contrato</mat-label>
                <input name="confirmarmail" matInput placeholder="Dirección de correo para envío de contrato"
                    formControlName="confirmarmail" required />
                <mat-hint *ngIf="form.controls['confirmarmail'].touched && form.controls['confirmarmail'].invalid"
                    class="alert-danger">
                    <span *ngIf="form.controls['confirmarmail'].errors.required" class="error"> El correo electrónico es
                        obligatorio</span>
                    <span *ngIf="form.controls['confirmarmail'].errors.mustMatch" class="error"> Los correos no
                        coinciden</span>
                </mat-hint>


            </mat-form-field>
        </div>
        <div class="col-md-12 col-xl-6">
        
                <mat-checkbox name="productorprimario" formControlName="productorprimario">Productor Primario</mat-checkbox>
            
        </div>
        
        <div class="com-md-12 col-xl-6">
            <button *ngIf="!formSend" type="submit" [disabled]="!form.valid" class="btn mbutton">Enviar</button>
            <p class="center" *ngIf="formSend">{{txtResultSend}}</p>
        </div>
    </form>

</mat-card>


<footer>
    <p>&copy; Dinanet 2023</p>
</footer>