import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Apoderado } from '../models/apoderado';
import { DataAgentPro } from '../models/dataagentpro';
import { ContratoG5 } from '../models/contratog5';
import { Contrato } from '../models/contrato';
import { Reqapoderado } from '../models/reqapoderado';

@Injectable()

export class WsWebservices {



    constructor(private http: HttpClient) {

    }

    //ENVIAR SMS DESDE OVH
    sendSMS(clientid: string, secretid: string, documentid: string): Observable<any> {
        console.log('ENTRA EN ENVIAR SMS', clientid, secretid, documentid);
        let rutaWebService = 'https://apisigned.signedidsecured.com/sendSMSOvh?clientid=' + clientid + '&secretid=' + secretid + '&documentid=' + documentid;
        return this.http.get(rutaWebService);
    }

    getviewdocumento(clientid: string, secretid: string, documentid: string): Observable<any> {
        console.log('entra en obtener dashboard autorizador');
        let rutaWebService = 'https://apisigned.signedidsecured.com/getviewcontract?clientid=' + clientid + '&secretid=' + secretid + '&documentid=' + documentid;
        return this.http.get(rutaWebService);
    }

    firmar(data): Observable<any> {
        console.log('entra en obtener dashboard autorizador');
        let rutaWebService = 'https://apisigned.signedidsecured.com/singpdf';
        let json = JSON.stringify(data);
        console.log('El JSON ENVIADO ES. ', data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(rutaWebService, json,
            { headers: new HttpHeaders().set('Content-Type', 'application/json'), responseType: 'text' });

    }

    firmarCertificado(data): Observable<any> {
        console.log('EL DATO RECIBIDO ES: ', data);
        console.log('entra en obtener dashboard autorizador');
        let rutaWebService = 'https://apisigned.signedidsecured.com/singpdfcert';
        let json = JSON.stringify(data);
        console.log('El JSON ENVIADO ES. ', data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(rutaWebService, json,
            { headers: new HttpHeaders().set('Content-Type', 'application/json'), responseType: 'text' });
    }

    getviewapoderado(clientid: string, secretid: string, apoderadoid: string): Observable<any> {
        console.log('entra en obtener dashboard autorizador');
        let rutaWebService = 'https://apisigned.signedidsecured.com/getviewauthorized?clientid=' + clientid + '&secretid=' + secretid + '&apoderadoid=' + apoderadoid;
        return this.http.get(rutaWebService);
    }



    setAutorized(reqapoderado: Reqapoderado): Observable<any> {

        console.log('entra en obtener dashboard autorizador');
        let rutaWebService = 'https://apisigned.signedidsecured.com/setapoderadog5';
        let json = JSON.stringify(reqapoderado);
        console.log('El JSON ENVIADO ES. ', reqapoderado);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(rutaWebService, json,
            { headers: new HttpHeaders().set('Content-Type', 'application/json'), responseType: 'text' });

    }

}