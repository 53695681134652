import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Firma } from '../../models/firma';
import { Contrato } from '../../models/contrato';
import { ActivatedRoute } from '@angular/router';
import { WsWebservices } from '../../webservices/wswebservice';
import { ContratoG5 } from '../../models/contratog5';
import { Container } from '@angular/compiler/src/i18n/i18n_ast';
import { DatePipe } from '@angular/common';
import * as pkijs from 'pkijs';

@Component({
  selector: 'app-firma',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.css']
})
export class FirmaComponent implements OnInit {

  @ViewChild("digito1") input1: ElementRef;
  @ViewChild("digito2") input2: ElementRef;
  @ViewChild("digito3") input3: ElementRef;
  @ViewChild("digito4") input4: ElementRef;
  @ViewChild("digito5") input5: ElementRef;
  @ViewChild("digito6") input6: ElementRef;

  pdfSinFirmar = 'assets/CONTRATO_AICA.pdf';
  pdf;

  firma: Firma;
  firmando_sms: boolean = false;
  firmando_cert: boolean = false;
  firmado: boolean = false;
  nombreArchivo: string = '';
  numero = [];
  codigo: string = '';
  completado: boolean = false;
  name: string;
  codigoFirma: string;
  loading: boolean = false;
  mostrarPass: boolean = false;
  password: string;
  conpassword: string;
  certificadoBase64: string;

  //BASE DE DATOS
  datosBD: Contrato;

  //VARIABLES ENTRADA URL
  clientid: string;
  secretid: string;
  documentid: string;

  constructor(private route: ActivatedRoute,
    private ws: WsWebservices,
    private datePipe: DatePipe) {
    this.firma = new Firma(); //MODELO VISTA
    this.datosBD = new Contrato(); //MODELO BASE DE DATOS
    //this.datosG5 = new DataAgentPro(); //MODELO WS G5
  }

  ngOnInit(): void {
    //Activar Loadging
    this.loading = true;
    
    this.route.queryParams.subscribe(params => {
      this.clientid = params['clientid'];
      this.secretid = params['secretid'];
      this.documentid = params['documentid'];
    })
    this.ws.getviewdocumento(this.clientid, this.secretid, this.documentid).subscribe(data => {
      this.datosBD = data;
      console.log(data);
      this.firma.pdfBase64 = this.datosBD.documentbase64;
      this.firma.nombrePdf = this.datosBD.domaing5;
      this.firma.guidContrato = this.datosBD.documentid;
      this.firma.codContrato = this.datosBD.documentidg5;
      this.pdf = 'data:image/jpg;base64,' + this.firma.pdfBase64;
      console.log('La respuesta es', this.datosBD);
      // Desactivar Loading
     this.loading = false;

    }, error => {
      console.log('Se ha producido un error', error);
      this.loading = false;
    })

    console.log(this.datosBD);
  }

  capturarCodigo() {
    console.log('ENTRA EN CAPTURAR CODIGO');
    this.firmando_sms = true;
    setTimeout(() => {
      this.input1.nativeElement.focus();
    }, 200);
    this.ws.sendSMS(this.clientid, this.secretid, this.documentid).subscribe(data => {
      console.log(data);
      this.codigoFirma = data.codsms;
    }, error => {
      console.log('Se ha producido un error', error);
    });

  }

  capturarCertificado() {
    console.log('ENTRA EN CAPTURAR CERTIFICADO');
    this.firmando_cert = true;
  }

  buscarCertificado() {
    const certificateSelectHandler = (selectedCertificate: string) => {
      // Procesar el certificado seleccionado
      console.log('Certificado seleccionado:', selectedCertificate);

      // Realizar la lógica de autenticación o cualquier otra acción necesaria
    };

    try {
      const certificateImportHandler = (event: any) => {
        const file = event.target.files[0];
        this.nombreArchivo = file.name;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileBuffer = new Uint8Array(e.target.result);
          this.certificadoBase64 = btoa(String.fromCharCode.apply(null, fileBuffer));
          console.log(file.name);
          console.log('Contenido del archivo:', fileBuffer);

        };
        this.mostrarPass = true;
        reader.readAsArrayBuffer(file);
      };

      const inputElement = document.createElement('input');
      inputElement.type = 'file';
      inputElement.addEventListener('change', certificateImportHandler, false);
      inputElement.click();
    } catch (error) {
      console.error('Error al abrir la pantalla de selección de certificado:', error);
      // Manejar el error de apertura de la pantalla de selección del certificado
    }
  }

  firmarCertificado() {
    if (this.conpassword == this.password) {
      //Activar Loading
      this.loading = true;
      this.datosBD.date_signed = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
      this.datosBD.hour_signed = this.datePipe.transform(new Date(), 'HH:mm');
      this.datosBD.passCertificado = this.password;
      this.datosBD.certificadoBase64 = this.certificadoBase64;
      this.ws.firmarCertificado(this.datosBD).subscribe(data => {
        this.pdf = 'data:image/jpg;base64,' + data;
        this.firmado = true;
        this.loading = false;
      }, error => {
        this.loading = false;
        console.log('Se ha producido un error', error);
      })
    } else {
      alert('Las contraseñas introducidas no coinciden');
    }
  }

  keyPress(event: KeyboardEvent, id: number) {
    this.completado = false;
    this.numero[id] = event;
    this.codigo = '';
    this.numero.forEach(e => {
      this.codigo += e;
      if (this.codigo.length == 6) {
        this.completado = true;
      }
      if (this.numero[id] != '') {
        switch (id) {
          case 0:
            this.input2.nativeElement.focus();
            break;
          case 1:
            this.input3.nativeElement.focus();
            break;
          case 2:
            this.input4.nativeElement.focus();
            break;
          case 3:
            this.input5.nativeElement.focus();
            break;
          case 4:
            this.input6.nativeElement.focus();
            break;
        }
      }
    });
  }

  enviarCodigo() {
    if (this.codigo == this.codigoFirma) {
      //Activar Loading
      this.loading = true;
      this.datosBD.date_signed = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
      this.datosBD.hour_signed = this.datePipe.transform(new Date(), 'HH:mm');
      this.ws.firmar(this.datosBD).subscribe(data => {
        this.pdf = 'data:image/jpg;base64,' + data;
        this.firmado = true;
        this.loading = false;
      }, error => {
        this.loading = false;
        console.log('Se ha producido un error', error);
      })
    } else {
      alert('El codigo no es correcto');
      this.input1.nativeElement.focus();
    }

  }

  solicitarNuevoCodigo() {
    console.log('Entra en solicitar nuevo codigo')
  }

}
