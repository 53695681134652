import { Component, OnInit } from '@angular/core';
import { DatosApoderado } from '../../models/datos-apoderado';
import { DataAgentPro } from '../../models/dataagentpro';
import { Apoderado } from '../../models/apoderado';
import { ActivatedRoute } from '@angular/router';
import { WsWebservices } from '../../webservices/wswebservice';
import { Reqapoderado } from '../../models/reqapoderado';

import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { ValidarIguales, ComporbarDni} from '../../validators/mail.validator';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-solicitud-datos',
  templateUrl: './solicitud-datos.component.html',
  styleUrls: ['./solicitud-datos.component.css']
})
export class SolicitudDatosComponent implements OnInit {

  mailsDistintos:boolean
  form: FormGroup;
  formSend: boolean = false;
  loading: boolean = false;
  datos: DatosApoderado;
  datosBD: Apoderado;
  datosG5: DataAgentPro;
  clientid;
  secretid;
  apoderadoid;
  txtResultSend;

  constructor(private route: ActivatedRoute,
              private ws: WsWebservices,
              private formBuilder: FormBuilder) { 
    this.datos = new DatosApoderado();
    this.datosBD = new Apoderado();
    this.datosG5 = new DataAgentPro();
  }

  ngOnInit(): void {
   // this.loading = true;
  
    this.form  = this.formBuilder.group({
      nombre: new FormControl('', [Validators.required, Validators.minLength(3)]),
      direccion: new FormControl('', [Validators.required, Validators.minLength(3)]),
      localidad: new FormControl('', [Validators.required, Validators.minLength(2)]),
      codpostal: new FormControl('', [Validators.required,Validators.minLength(5),Validators.maxLength(5)]),
      codpostalex: new FormControl(''),
      telefono:  new FormControl('', [Validators.required]),
      dni:       new FormControl('', [Validators.required]),
      mail:      new FormControl('', [Validators.required, Validators.email]),
      confirmarmail:      new FormControl('', [Validators.required]),
      productorprimario: false,
      nospain: new FormControl('false'),
    },
    {
    
      validators:[ValidarIguales('mail', 'confirmarmail'), ComporbarDni('dni', 'nospain')] 
    }); 
   this.form.controls['nospain'].setValue(false);
    
    this.route.queryParams.subscribe(params =>{
      this.clientid = params['clientid'];
      this.secretid = params['secretid'];
      this.apoderadoid = params['apoderadoid'];
    })
    this.ws.getviewapoderado(this.clientid,this.secretid,this.apoderadoid).subscribe(data => {
      this.datosBD = data;
      console.log('La respuesta es', this.datosBD);
      this.loading = false;
    }, error => {
      console.log('Se ha producido un error', error);
      this.loading = false;
    })
    
    console.log(this.datos);
    
  }

pulsaCheck(ob: MatCheckboxChange) {
  
  this.form.controls['nospain'].setValue(ob.checked);
  console.log('El valor del control es ',this.form.get('nospain').value)
  let valor = this.form.get('nospain').value;
  
       if(valor){
        console.log('entra en no español y codpostalex es',this.form.get('codpostalex').value)
        this.form.controls['codpostalex'].setValue(this.form.get('codpostal').value);
       }else{
        console.log('entra en español y codpostalex es',this.form.get('codpostal').value)
       
        this.form.controls['codpostal'].setValue(this.form.get('codpostalex').value);
       }   
}

actualizaCodPostal(e:Event){
  console.log('ENTRA EN ACUTALIZA COD POSTAL');
  this.form.controls['codpostal'].setValue('12345');
}

  enviar() {
    console.log(this.datos);
    this.loading = true;
    this.datosG5.clte_prov = this.datosBD.apoderadog5;
    this.datosG5.nombre = this.form.get('nombre').value;
    this.datosG5.direccion = this.form.get('direccion').value;
    if(this.form.get('nospain').value){
      this.datosG5.codigo_postal = this.form.get('codpostalex').value;
    }else {
      this.datosG5.codigo_postal = this.form.get('codpostal').value;
    }
    
    this.datosG5.localidad = this.form.get('localidad').value;
    this.datosG5.tlf_movil = this.form.get('telefono').value;
    this.datosG5.e_mail = this.form.get('mail').value;
    this.datosG5.nif_cif = this.form.get('dni').value;
    this.datosG5.cargo = '1';
    this.datosG5.departamento = '1';
    this.datosG5.tp_contacto = 'P';
    console.log(this.form.get('productorprimario').value);
    console.log('Los datos a enviar a g5 son',this.datosG5);
    if (this.form.get('productorprimario').value) {
      this.datosG5.mm_sw_productor_primario = "1";
    } else {
      this.datosG5.mm_sw_productor_primario = "0";
    }
    let reqapoderado: Reqapoderado = new Reqapoderado();
    reqapoderado.apoderado = this.datosBD;
    reqapoderado.dataagentpro = this.datosG5;
    this.ws.setAutorized(reqapoderado).subscribe(data => {
      console.log('La respuesta es', data);
      this.formSend = true;
      this.loading = false;
      let response = JSON.parse(data);
      if(response.strError !== '' || !response.objReturn){
        this.txtResultSend = "No hemos podido grabar los datos, pongase en contacto con su proveedor. " + response.strError;
      }else {
        this.txtResultSend = "Los datos se han enviado correctamente. Por favor, cierre la ventana del navegador";
      }
    }, error => {
      console.log('Se ha producido un error', error);
      this.formSend = true;
      this.loading = false;
      this.txtResultSend ="No hemos podido recibir los datos. Vuelva a cargar la página e intentelo de nuevo.";
    })
  }

}
