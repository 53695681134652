

export class DatosApoderado{
    proveedor: number;
    Nombre_apoderado: string;
    Direccion_apoderado: string;
    Localidad_apoderado: string;
    Codigo_postal_apoderado: string;
    DNI_apoderado: string;
    Telefono_SMS: string;
    e_Mail_ctos: string;
}