import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FirmaComponent } from './views/firma/firma.component';
import { SolicitudDatosComponent } from './views/solicitud-datos/solicitud-datos.component';


const routes: Routes = [
  {
    path: 'firma',
    component: FirmaComponent,
  },
  {
    path: 'datos-apoderado',
    component: SolicitudDatosComponent,
  },
  { path: '', redirectTo: '/firma', pathMatch: 'full' },
  { path: '**', redirectTo: '/firma'} 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
