export class Apoderado {
    clientid: string;
    secretid: string;
    apoderadoid: string;
    tokeng5: string;
    domaing5: string;
    enterpriseg5: string;
    ape_en_representacion: string;
    apoderadog5: string;
    app_en_representacion: string;
    proveedorPrimario: string;
}