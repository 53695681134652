<!--- Loading -->
<div *ngIf="loading" class="loading">
    <div>
        <p style="text-align:center">Esperando a Firma de Contratos AICA</p>
    </div>
    <div>
        <div class="spin"></div>
    </div>
</div>

<div *ngIf="!loading">
    


        <div style="background-color:rgb(44, 44, 201) ;">
        <div class="cabecera fondoazul">
            <mat-card style="margin-right: 30px;">
                <img src="/assets/logo dinanet.png" alt="AICA">
            </mat-card>
            <div style="width:70%">
                <h2>Solicitud de datos de apoderado para la firma de contratos</h2>
                <h3>Dominio: {{datosBD.domaing5}} </h3>
                <h3>Empresa que los solicita: {{datosBD.ape_en_representacion}}</h3>
                <h3 class="final">Proveedor para el que se solicita: {{datosBD.app_en_representacion}}</h3>
            </div>
        </div>
        </div>
    
    <mat-card class="m-3">
        <div class="contenedor">
            <div class="pdf">
                <ngx-extended-pdf-viewer [src]="pdf" height="100%" [showBookmarkButton]=false [showOpenFileButton]=false [textLayer]="true"
                    [zoom]="'page-fit'" useBrowserLocale="true">
                </ngx-extended-pdf-viewer>
            </div>
            <div class="firma">
                <img src="/assets/MERCANXT.jpg" width="80%" alt="">
                <div *ngIf="!firmando_sms && !firmando_cert && !firmado">
                    <p>Seleccione una opción para firmar el contrato</p>
                    <button class="btn button" (click)="capturarCertificado()">Firmar por Certificado</button>
                    <button class="btn button" (click)="capturarCodigo()">Firmar por SMS</button>
                </div>
                <div *ngIf="firmando_sms && !firmado">
                    <p>Hemos enviado un SMS con el código de verificación para la firma</p>
                    <input class="input" maxlength="1" #digito1 id="digito1" type="text" name="digito1" autocomplete="nope" (focus)="digito1.select()"
                        (input)="keyPress($event.target.value,0);">
                    <input class="input" maxlength="1" #digito2 id="digito2" type="text" name="digito2" autocomplete="nope" (focus)="digito2.select()"
                        (input)="keyPress($event.target.value,1);">
                    <input class="input" maxlength="1" #digito3 id="digito3" type="text" name="digito3" autocomplete="nope" (focus)="digito3.select()"
                        (input)="keyPress($event.target.value,2);">
                    <input class="input" maxlength="1" #digito4 id="digito4" type="text" name="digito4" autocomplete="nope" (focus)="digito4.select()"
                        (input)="keyPress($event.target.value,3);">
                    <input class="input" maxlength="1" #digito5 id="digito5" type="text" name="digito5" autocomplete="nope" (focus)="digito5.select()"
                        (input)="keyPress($event.target.value,4);">
                    <input class="input" maxlength="1" #digito6 id="digito6" type="text" name="digito6" autocomplete="nope" (focus)="digito6.select()"
                        (input)="keyPress($event.target.value,5);">
                    <div>
                        <button class="btn button" [disabled]="completado === false" (click)="enviarCodigo();">Validar</button>
                    </div>
                    <p>¿No ha recibido el código?
                        <span class="enlace" (click)="solicitarNuevoCodigo()">Solicitelo de nuevo</span>
                    </p>
                </div>
                <div *ngIf="firmando_cert && !firmado">
                    <p>Seleccione el certificado para firmar, pulsando el botón Buscar Certificado y cumplimente su contraseña</p>
                    <div *ngIf="!mostrarPass">
                        <button class="btn button" (click)="buscarCertificado();">Buscar Certificado</button>
                    </div>
                    <div *ngIf="mostrarPass">
                        <p>
                            Archivo de certificado seleccionado: {{nombreArchivo}}
                        </p>
                        <div>
                            <P>Por favor, indique la contraseña con la que exportó el certificado</P>
                            <input [(ngModel)]="password" id="contrasena" type="password" name="contrasena" autocomplete="nope">
                        </div>
                        <div>
                            <P>Repita la contraseña, por favor.</P>
                            <input [(ngModel)]="conpassword" id="conpassword" type="password" name="conpassword" autocomplete="nope">
                        </div>
                        <div>
                            <button class="btn button" (click)="firmarCertificado();">Firmar</button>
                        </div>
                    </div>


                </div>
                <div *ngIf="firmado">
                    <p>El documento se ha firmado correctamente. Puede descargarlo desde el botón de descarga del visor PDF</p>
                </div>
                <!--
                    Suprimimos el logo de AICA
                <div>
                    <img src="/assets/logo_aica.png" alt="AICA" height="90px">
                </div>
            -->
            </div>
        </div>
    </mat-card>
</div>