export class Contrato {
    clientid: string;
    secretid: string;
    documentid: string;
    documentbase64: string;
    mailsigned: string;
    phonesigned: string;
    tokeng5: string;
    domaing5: string;
    enterpriseg5: string;
    documentidg5: string;
    apoderadog5: string;
    contractcode: string;
    codsms: string;
    date_signed: string;
    hour_signed: string;

    ape_nombre_apoderado: string;
    ape_direccion_apoderado: string;
    ape_cod_postal_apoderado: string;
    ape_localidad_apoderado: string;
    ape_dni_apoderado: string;
    ape_en_representacion: string;
    ape_direccion_empresa: string;
    ape_localidad_empresa: string;
    ape_cod_postal_empresa: string;
    ape_nif_empresa: string;
    app_nombre_apoderado: string;
    app_direccion_apoderado: string;
    app_cod_postal_apoderado: string;
    app_localidad_apoderado: string;
    app_dni_apoderado: string;
    app_en_representacion: string;
    app_direccion_empresa: string;
    app_localidad_empresa: string;
    app_cod_postal_empresa: string;
    app_nif_empresa: string;

    //Variables para la firma por certicado
    certificadoBase64: string;
    passCertificado: string;
}